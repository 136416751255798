import { browser } from '$app/environment'
import { PUBLIC_POSTHOG_HOST, PUBLIC_POSTHOG_KEY } from '$env/static/public'
import { PostHog } from 'posthog-js-lite'

const MOBILE = 'Mobile'
const ANDROID = 'Android'
const TABLET = 'Tablet'
const ANDROID_TABLET = ANDROID + ' ' + TABLET
const IPAD = 'iPad'
const APPLE = 'Apple'
const APPLE_WATCH = APPLE + ' Watch'
const BLACKBERRY = 'BlackBerry'

const NINTENDO = 'Nintendo'
const PLAYSTATION = 'PlayStation'
const XBOX = 'Xbox'
const WINDOWS = 'Windows'
const WINDOWS_PHONE = WINDOWS + ' Phone'
const NOKIA = 'Nokia'
const OUYA = 'Ouya'
const GENERIC = 'Generic'
const GENERIC_MOBILE = GENERIC + ' ' + MOBILE.toLowerCase()
const GENERIC_TABLET = GENERIC + ' ' + TABLET.toLowerCase()

const XBOX_REGEX = new RegExp(XBOX, 'i')
const PLAYSTATION_REGEX = new RegExp(PLAYSTATION + ' \\w+', 'i')
const NINTENDO_REGEX = new RegExp(NINTENDO + ' \\w+', 'i')
const BLACKBERRY_REGEX = new RegExp(BLACKBERRY + '|PlayBook|BB10', 'i')

export const detectDevice = function (userAgent: string): string {
	if (NINTENDO_REGEX.test(userAgent)) {
		return NINTENDO
	} else if (PLAYSTATION_REGEX.test(userAgent)) {
		return PLAYSTATION
	} else if (XBOX_REGEX.test(userAgent)) {
		return XBOX
	} else if (new RegExp(OUYA, 'i').test(userAgent)) {
		return OUYA
	} else if (new RegExp('(' + WINDOWS_PHONE + '|WPDesktop)', 'i').test(userAgent)) {
		return WINDOWS_PHONE
	} else if (/iPad/.test(userAgent)) {
		return IPAD
	} else if (/iPod/.test(userAgent)) {
		return 'iPod Touch'
	} else if (/iPhone/.test(userAgent)) {
		return 'iPhone'
	} else if (/(watch)(?: ?os[,/]|\d,\d\/)[\d.]+/i.test(userAgent)) {
		return APPLE_WATCH
	} else if (BLACKBERRY_REGEX.test(userAgent)) {
		return BLACKBERRY
	} else if (/(kobo)\s(ereader|touch)/i.test(userAgent)) {
		return 'Kobo'
	} else if (new RegExp(NOKIA, 'i').test(userAgent)) {
		return NOKIA
	} else if (
		// Kindle Fire without Silk / Echo Show
		/(kf[a-z]{2}wi|aeo[c-r]{2})( bui|\))/i.test(userAgent) ||
		// Kindle Fire HD
		/(kf[a-z]+)( bui|\)).+silk\//i.test(userAgent)
	) {
		return 'Kindle Fire'
	} else if (/(Android|ZTE)/i.test(userAgent)) {
		if (
			!new RegExp(MOBILE).test(userAgent) ||
			/(9138B|TB782B|Nexus [97]|pixel c|HUAWEISHT|BTV|noble nook|smart ultra 6)/i.test(userAgent)
		) {
			if (
				(/pixel[\daxl ]{1,6}/i.test(userAgent) && !/pixel c/i.test(userAgent)) ||
				/(huaweimed-al00|tah-|APA|SM-G92|i980|zte|U304AA)/i.test(userAgent) ||
				(/lmy47v/i.test(userAgent) && !/QTAQZ3/i.test(userAgent))
			) {
				return ANDROID
			}

			return ANDROID_TABLET
		} else {
			return ANDROID
		}
	} else if (new RegExp('(pda|' + MOBILE + ')', 'i').test(userAgent)) {
		return GENERIC_MOBILE
	} else if (
		new RegExp(TABLET, 'i').test(userAgent) &&
		!new RegExp(TABLET + ' pc', 'i').test(userAgent)
	) {
		return GENERIC_TABLET
	} else {
		return ''
	}
}

export const detectDeviceType = function (userAgent: string): string {
	const device = detectDevice(userAgent)

	if (
		device === IPAD ||
		device === ANDROID_TABLET ||
		device === 'Kobo' ||
		device === 'Kindle Fire' ||
		device === GENERIC_TABLET
	) {
		return TABLET
	} else if (device === NINTENDO || device === XBOX || device === PLAYSTATION || device === OUYA) {
		return 'Console'
	} else if (device === APPLE_WATCH) {
		return 'Wearable'
	} else if (device) {
		return MOBILE
	} else {
		return 'Desktop'
	}
}

export const phg = browser
	? new PostHog(PUBLIC_POSTHOG_KEY, {
			host: PUBLIC_POSTHOG_HOST,
			autocapture: false,
			disabled: import.meta.env.MODE === 'development',
		})
	: null
